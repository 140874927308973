import Image from "next/image";
import { useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { format } from "date-fns";
import { useTranslations } from "next-intl";
import { SearchType } from "types/Search.type";
import { DestinationType } from "types/Destination.type";

export default function SearchBar({
  preferences,
  destinations,
  setPreferences,
}: {
  preferences: SearchType;
  destinations: DestinationType[];
  setPreferences: any;
}) {
  const guestsOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const [filteredDestinations, setFilteredDestinations] =
    useState(destinations);
  const [displayDestination, setDisplayDestination] = useState(false);
  const [displayCheckInCalendar, setDisplayCheckInCalendar] = useState(false);
  const [displayCheckOutCalendar, setDisplayCheckOutCalendar] = useState(false);
  const [displayGuestsOptions, setDisplayGuestsOptions] = useState(false);
  const [displayChildrenOptions, setDisplayChildrenOptions] = useState(false);
  const [selectedDestination, setSelectedDestination] = useState("");
  const t = useTranslations("home.search");

  const handleChange = (field: string, value: string | number) => {
    let tempPreferences: SearchType = {};

    if (field === "guests" && (Number(value) <= 0 || Number(value) > 10)) {
      tempPreferences = { ...preferences, [field]: 1 };
    } else {
      tempPreferences = { ...preferences, [field]: value };
    }
    setPreferences(tempPreferences);

    field === "checkIn" && setDisplayCheckInCalendar(false);
    field === "checkOut" && setDisplayCheckOutCalendar(false);
  };

  const filterLocations = (value: string) => {
    setSelectedDestination(value)
    if(!value) {
      setPreferences({...preferences, location: null })
    }
    const filteredOptions = destinations.filter((el) =>
      el.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredDestinations(filteredOptions);
    setDisplayDestination(true);
  };

  useEffect(() => {
    setSelectedDestination(
      destinations.filter((el) => el.id === preferences.location)[0]?.name
    );
    setFilteredDestinations(destinations);
  }, [destinations]);

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 py-4 relative z-20">
        <div className="my-2 md:my-4 relative">
          <div className="bg-white text-[#1C1B1F] text-[18px] -mb-[14px] ml-4 p-1 relative z-10 w-fit">
            {t("destination")}
          </div>
          <Image
            src="/img/destination.svg"
            alt="Destination icon"
            width={26}
            height={26}
            className="absolute top-10 left-2"
          />
          <input
            value={selectedDestination}
            onClick={() => {
              setDisplayDestination(!displayDestination);
            }}
            onChange={(e) => filterLocations(e.target.value)}
            placeholder=""
            maxLength={100}
            className="border border-solid border-inputBorder text-black w-full py-[18px] px-10 rounded-[4px]"
          />
          {displayDestination && (
            <div
              className="absolute top-20 w-full bg-white max-h-[200px] overflow-scroll z-20"
              style={{ boxShadow: "rgba(17, 34, 17, 0.05) 0px 4px 16px 0px" }}
            >
              {filteredDestinations.map((destination: DestinationType) => {
                return (
                  <div
                    key={destination.id}
                    onClick={() => {
                      handleChange("location", destination.id);
                      setSelectedDestination(destination.name);
                      setDisplayDestination(false);
                    }}
                    className="text-black text-start py-1 px-8 hover:bg-primary hover:!text-white rounded-[8px] show-from-above-animation"
                  >
                    {destination.name}
                  </div>
                );
              })}
            </div>
          )}
        </div>

        <div className="my-2 md:my-4">
          <div className="bg-white text-[#1C1B1F] text-[18px] -mb-[14px] ml-4 p-1 relative z-10 w-fit">
            {t("checkIn")}
          </div>
          <div className='relative'>
            <div onClick={() => {setDisplayCheckInCalendar(!displayCheckInCalendar); setDisplayCheckOutCalendar(false);}} >
              <input 
                value={preferences.checkIn ? format(preferences.checkIn, 'dd/MM/yyyy') : preferences.checkIn}
                disabled
                placeholder=""
                className="border border-solid border-inputBorder text-black w-full py-[18px] px-4 rounded-[4px]"
              />
              <Image
                src="/img/calendar.svg"
                alt="Calendar icon"
                width={26}
                height={26}
                className="absolute top-5 right-4"
              />
            </div>
            {
              displayCheckInCalendar &&
              <div className='absolute top-16 text-black show-from-above-animation !bg-white rounded-[12px] z-30'>
                <Calendar 
                  onChange={(value: any) => handleChange('checkIn', value)} 
                  value={preferences.checkIn} 
                  defaultValue={preferences.checkIn} 
                  className='text-black !bg-white'
                  tileClassName='!text-black !p-4 !w-[30px] z-20 hover:!bg-primary hover:!text-white hover:!rounded-[12px] !my-1'
                  minDate={new Date()}
                />
                <div className='mb-4 mr-6 flex justify-end'>
                  <button onClick={() =>  handleChange('checkIn', '')} className='main-button'>{t('clear')}</button>
                </div>
              </div>
            }
          </div>
        </div>

        <div className="my-2 md:my-4">
          <div className="bg-white text-[#1C1B1F] text-[18px] -mb-[14px] ml-4 p-1 relative z-10 w-fit">
            {t("checkOut")}
          </div>
          <div className='relative'>
            <div onClick={() => {setDisplayCheckOutCalendar(!displayCheckOutCalendar); setDisplayCheckInCalendar(false);}}>
              <Image src='/img/calendar.svg' alt='Calendar icon' width={26} height={26} className='absolute top-5 right-4 z-10' />
              <input 
                value={preferences.checkOut ? format(preferences.checkOut, 'dd/MM/yyyy') : preferences.checkOut}
                disabled
                placeholder=""
                className="border border-solid border-inputBorder text-black w-full py-[18px] px-4 rounded-[4px]"
              />
            </div>
            {
              displayCheckOutCalendar &&
              <div className='absolute top-16 text-black show-from-above-animation !bg-white rounded-[12px] z-30'>
                 <Calendar 
                    onChange={(value: any) => handleChange('checkOut', value)} 
                    value={preferences.checkOut} 
                    defaultValue={preferences.checkOut} 
                    className='text-black !bg-white'
                    tileClassName='!text-black !p-4 !w-[30px] hover:!bg-primary hover:!text-white hover:!rounded-[12px] !my-1'
                    minDate={
                      preferences.checkIn ? new Date(preferences.checkIn.getTime() + 24 * 60 * 60 * 1000)
                      : new Date()}
                  />
                <div className='mb-4 mr-6 flex justify-end'>
                  <button onClick={() =>  handleChange('checkOut', '')} className='main-button'>{t('clear')}</button>
                </div>
              </div>
            }
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div className="my-2 md:my-4 relative">
            <div className="bg-white text-[#1C1B1F] text-[18px] -mb-[14px] ml-4 p-1 relative z-10 w-fit">
              {t("adults")}
            </div>
            <div onClick={() => setDisplayGuestsOptions(!displayGuestsOptions)}>
              <Image
                src="/img/guests.svg"
                alt="Guests icon"
                width={32}
                height={32}
                className="absolute top-9 left-2"
              />
              <input
                value={preferences.guests}
                onChange={(e) => {
                  handleChange("guests", e.target.value);
                }}
                type="number"
                placeholder=""
                max={10}
                className="border border-solid border-inputBorder text-black w-full py-[18px] px-10 rounded-[4px]"
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                className="absolute top-9 right-3 cursor-pointer"
              >
                <path
                  d="M5.25 9L12 15.75L18.75 9"
                  stroke="#112211"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            {displayGuestsOptions && (
              <div
                className="absolute top-20 w-full bg-white max-h-[200px] overflow-scroll"
                style={{ boxShadow: "rgba(17, 34, 17, 0.05) 0px 4px 16px 0px" }}
              >
                {guestsOptions.map((guestsNumber: number) => {
                  return (
                    <div
                      key={guestsNumber}
                      onClick={() => {
                        handleChange("guests", guestsNumber);
                        setDisplayGuestsOptions(false);
                      }}
                      className="text-black text-start py-1 px-8 hover:bg-primary hover:text-white rounded-[8px] show-from-above-animation"
                    >
                      {guestsNumber}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <div className="my-2 md:my-4 relative">
            <div className="bg-white text-[#1C1B1F] text-[18px] -mb-[14px] ml-4 p-1 relative z-10 w-fit">
              {t("children")}
            </div>
            <div
              onClick={() => setDisplayChildrenOptions(!displayChildrenOptions)}
            >
              <Image
                src="/img/guests.svg"
                alt="Guests icon"
                width={32}
                height={32}
                className="absolute top-9 left-2"
              />
              <input
                value={preferences.children}
                onChange={(e) => {
                  handleChange("children", e.target.value);
                }}
                type="number"
                placeholder=""
                min={0}
                max={10}
                className="border border-solid border-inputBorder text-black w-full py-[18px] px-10 rounded-[4px]"
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                className="absolute top-9 right-3 cursor-pointer"
              >
                <path
                  d="M5.25 9L12 15.75L18.75 9"
                  stroke="#112211"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            {displayChildrenOptions && (
              <div
                className="absolute top-20 w-full bg-white max-h-[200px] overflow-scroll"
                style={{ boxShadow: "rgba(17, 34, 17, 0.05) 0px 4px 16px 0px" }}
              >
                {guestsOptions.map((guestsNumber: number) => {
                  return (
                    <div
                      key={guestsNumber}
                      onClick={() => {
                        handleChange("children", guestsNumber);
                        setDisplayChildrenOptions(false);
                      }}
                      className="text-black text-start py-1 px-8 hover:bg-primary hover:text-white rounded-[8px] show-from-above-animation"
                    >
                      {guestsNumber}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
